import s from '../AssuranceCallout.module.css';
import FindingCAPDetail from '../FindingCAPDetail/FindingCAPDetail';

interface ISLAAssuranceCallout {
  calloutData: any
}
export default function SLAAssuranceCallout({ calloutData }: ISLAAssuranceCallout) {
  return <>
    <div className={s.accordionContainer}>
      <FindingCAPDetail />
    </div>
  </>
}