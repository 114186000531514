import s from '../AssuranceCallout.module.css';
import FindingACADetail from '../FindingACADetail/FindingACADetail';

interface ITLAAssuranceCallout {
  calloutData: any
}
export default function TLAAssuranceCallout({ calloutData }: ITLAAssuranceCallout) {
  return <>
    <div className={s.accordionContainer}>
      <FindingACADetail />
    </div>
  </>
}