import { ENDPOINTS } from "../constants/endpoints";
import { CalloutType, RiskActionLine } from "../saga/risk.saga";
import { getEnumKeyByValue } from "../utils/common.utils";
import axiosClient from "./axiosClient.service";

export function getRiskTop(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_PROFILE+ (payload === undefined ? '' : payload))
}

export function getAvailablePeriod(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_AVAILABLE_PERIOD + (payload === undefined ? '' : payload))
}

export function getRiskKRICallout(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_KRI_CALLOUT + (payload === undefined ? '' : payload))
}

export function getRiskMitigationCallout(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_MITIGATION_CALLOUT + (payload === undefined ? '' : payload))
}

export function getGasBusinessFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GAS_BUSINESS_FLA + (payload === undefined ? '' : payload))
}

export function getLNGAFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LNGA_FLA + (payload === undefined ? '' : payload))
}

export function getGPFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GP_FLA + (payload === undefined ? '' : payload))
}

export function getLMTFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LMT_FLA + (payload === undefined ? '' : payload))
}

export function getGCOFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GCO_FLA + (payload === undefined ? '' : payload))
}


export function getGasBusinessSLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GAS_BUSINESS_SLA + (payload === undefined ? '' : payload))
}

export function getLNGASLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LNGA_SLA + (payload === undefined ? '' : payload))
}

export function getGPSLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GP_SLA + (payload === undefined ? '' : payload))
}

export function getLMTSLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LMT_SLA + (payload === undefined ? '' : payload))
}

export function getGCOSLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GCO_SLA + (payload === undefined ? '' : payload))
}

export function getGasBusinessTLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GAS_BUSINESS_TLA + (payload === undefined ? '' : payload))
}

export function getLNGATLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LNGA_TLA + (payload === undefined ? '' : payload))
}

export function getGPTLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GP_TLA + (payload === undefined ? '' : payload))
}

export function getLMTTLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LMT_TLA + (payload === undefined ? '' : payload))
}

export function getGCOTLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GCO_TLA + (payload === undefined ? '' : payload))
}

export function getLNGAAssetCallout(payload?: any, line: RiskActionLine = RiskActionLine.fla, calloutType: CalloutType = CalloutType.Other) {
    const url = calloutType === CalloutType.ScheduleCompliance ? ENDPOINTS.GET_LNGA_ASSURANCE_SCHEDULE_COMP_CALLOUT : ENDPOINTS.GET_LNGA_ASSURANCE_CALLOUT;
    return axiosClient.get(url + (line === RiskActionLine.fla ? '' : getEnumKeyByValue(RiskActionLine, line)) + (payload === undefined ? '' : payload))
}

export function getLMTAssetCallout(payload?: any, line: RiskActionLine = RiskActionLine.fla, calloutType: CalloutType = CalloutType.Other) {
    const url = calloutType === CalloutType.ScheduleCompliance ? ENDPOINTS.GET_LMT_ASSURANCE_SCHEDULE_COMP_CALLOUT : ENDPOINTS.GET_LMT_ASSURANCE_CALLOUT;
    return axiosClient.get(url + (line === RiskActionLine.fla ? '' : getEnumKeyByValue(RiskActionLine, line)) + (payload === undefined ? '' : payload))
}

export function getGCOAssetCallout(payload?: any, line: RiskActionLine = RiskActionLine.fla, calloutType: CalloutType = CalloutType.Other) {
    const url = calloutType === CalloutType.ScheduleCompliance ? ENDPOINTS.GET_GCO_ASSURANCE_SCHEDULE_COMP_CALLOUT : ENDPOINTS.GET_GCO_ASSURANCE_CALLOUT;
    return axiosClient.get(url + (line === RiskActionLine.fla ? '' : getEnumKeyByValue(RiskActionLine, line)) + (payload === undefined ? '' : payload))
}

export function getGPAssetCallout(payload?: any, line: RiskActionLine = RiskActionLine.fla, calloutType: CalloutType = CalloutType.Other) {
    const url = calloutType === CalloutType.ScheduleCompliance ? ENDPOINTS.GET_GP_ASSURANCE_SCHEDULE_COMP_CALLOUT : ENDPOINTS.GET_GP_ASSURANCE_CALLOUT;
    return axiosClient.get(url + (line === RiskActionLine.fla ? '' : getEnumKeyByValue(RiskActionLine, line)) + (payload === undefined ? '' : payload))
}