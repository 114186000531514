import { useSelector } from 'react-redux';
import { get } from 'lodash';
import s from '../AssuranceLines.module.css';

export interface FirstLineActionItemProps {
  type?: string;
}

const FirstLineActionItemComponent = ({ type }: FirstLineActionItemProps) => {
  const requirementAction = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceRequirementCompliance', [])
  );

  const scheduleAction = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceScheduleCompliance', [])
  );

  const calloutAction = useSelector((state) =>
		get(state, 'risk.assuranceAssetCallout.result.actionItems', [])
	);

  const renderActionItems = (actions: any, type: string) => {
    const actionItems =
      type === 'requirement'
        ? [
            { label: 'Overdue', value: actions?.overdueAction, barColor: s.overdue },
            { label: 'Not Assigned', value: actions?.notAssignedAction, barColor: s.notAssigned },
            { label: 'In Progress', value: actions?.inProgressAction, barColor: s.inProgress },
            { label: 'Closed', value: actions?.closedAction, barColor: s.closed },
          ]
        : [
            { label: 'Planned', value: actions?.plannedProgram, barColor: s.planned },
            { label: 'Conducted', value: actions?.conductedProgram, barColor: s.conducted },
            { label: 'Unplanned', value: actions?.unplannedProgram, barColor: s.unplanned },
            { label: 'Cancelled', value: actions?.cancelledProgram, barColor: s.cancelled },
            {
              label: 'Overdue',
              value: actions?.overdueProgram,
              barColor: s.overdue,
              style: { gridColumn: 'span 2' },
            },
          ];

    return (
      <div className={type === 'requirement' ? `${s.actionItems} ${s.requirementAction}` : `${s.actionItems} ${s.scheduleAction}`}>
        {actionItems.map((item, index) => (
          <div
            key={index}
            className={`${s.actionItem} ${item.barColor}`}
            style={item.style || {}}
          >
            <div className={s.bar}></div>
            <div>
              <p>{item.label}</p>
              <span>{item.value}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (type === 'requirement' && requirementAction) {
    return (
      <div className={s.actionItemsSection}>
        <div className={s.actionItemsHeader}>Action Items</div>
        {renderActionItems(requirementAction, 'requirement')}
      </div>
    );
  }

  if (type === 'schedule' && scheduleAction) {
    return (
      <div className={s.actionItemsSection}>
        <div className={s.actionItemsHeader}>Assurance Program</div>
        {renderActionItems(scheduleAction, 'schedule')}
      </div>
    );
  }

  if (calloutAction) {
    return (
      <div className={s.actionItemsSection}>
        <div className={s.actionItemsHeader}>Action Items</div>
        {renderActionItems(calloutAction, 'requirement')}
      </div>
    );
  }

  return null;
};

export default FirstLineActionItemComponent;