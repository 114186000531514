import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Collapse, Progress, Table } from 'antd';
import s from './FindingCAPDetail.module.css';
import { useState } from 'react';
import * as _ from 'lodash';

const FindingCAPDetail = () => {
  const { Panel } = Collapse;
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const classifications = useSelector((state) =>
    get(state, 'risk.assuranceAssetCallout.result.classifications', [])
  );

  const statusCount = ({ findingVal, capVal, overdueVal }: { overdueVal: number, findingVal: number, capVal: number }) => {
    return (
      <>
        <span className={s.statusCount}><span className={s.statusCountNo}>{findingVal}</span> Findings</span>
        <span className={s.statusCount}><span className={s.statusCountNo}>{capVal}</span> CAP</span>
        <span className={s.statusCount}><span className={s.statusCountNo}>{overdueVal}</span> Overdue</span>
      </>
    );
  };

  const renderCategoryCollapse = (category, categoryIdx) => {
    if (!category?.findings || category?.findings.length === 0) {
      return <p>No data available</p>;
    }

    return <Collapse
      key={'categoryIdx' + categoryIdx}
      className={s.childrenCollapse}
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <img
          alt="arrow"
          src="/img/icon/accordion-arrow-down.svg"
          className={isActive ? s.arrowActive : s.arrow}
        />
      )}
    >
      <Panel
        header={
          <div className={s.childPanelHeader}>
            <span className={s.childPanelSubHeader}>
              Category
            </span>
            <span className={s.checkListName}>
              {category.category}
            </span>
          </div>
        }
        key={'categoryIdx' + categoryIdx}
      >
        {category.findings.map((finding, findingIdx) => <div className={s.findingsContainer} key={'categoryIdx' + categoryIdx + 'itemIdx' + findingIdx}>
          <div className={s.findingBody}>
            <span className={s.childPanelSubHeader}>
              #{findingIdx + 1} Finding
            </span>
            <span className={s.actionDescription}>{finding.finding}</span>
            {finding.caps?.map((cap, capIdx) => <div className={s.findingActionItemContainer} key={'categoryIdx' + categoryIdx + 'itemIdx' + findingIdx + "cap" + capIdx}>
              <span className={s.childPanelSubHeader}>
                #{capIdx + 1} CAP
                <span style={{ color: 'white' }}>
                  {new Date(cap.dueDate).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </span>
              </span>

              <hr />
              <span className={s.actionDescription}>{cap.cap}</span>
            </div>)}
          </div>
        </div>)}
      </Panel>
    </Collapse >
  };

  const handlePanelChange = (keys) => {
    setActiveKeys(keys as string[]);
  };

  return (
    <>
      {classifications.length === 0 ? (
        <div className={s.noResult}>
          <img src="/img/icon/no-result.svg" alt="No results" />
          <h2>No data available!</h2>
        </div>
      ) :
        classifications.map((classification, classIdx) => <Collapse
          className={s.parentCollapse}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            !classification.overdueCount ? <></> : <img
              className={isActive ? `${s.arrowActive}` : `${s.arrow}`}
              alt="arrow"
              src="/img/icon/accordion-arrow-down.svg"
            />
          )}
          activeKey={activeKeys}
          onChange={(key) => {
            if (classification.overdueCount) {
              handlePanelChange(key)
            }
          }}
        >
          <Panel
            header={
              <div className={s.panelHeader}>
                <div className={`${s.accordionHeader} ${s.badge} ${s['badge-' + classification.classification?.toLowerCase()]}`}>{classification.classification?.toUpperCase()}</div>
                <div className={s.statusCountContainer}>
                  {statusCount({
                    capVal: classification.capCount,
                    findingVal: classification.findingCount,
                    overdueVal: classification.overdueCount
                  })}
                </div>
              </div>
            }
            key={"classification" + classIdx}
          >
            <div className={activeKeys.includes("classification" + classIdx) ? s.expandedContent : ''}>
              {classification.categories.map((category, categoryIdx) => renderCategoryCollapse(category, categoryIdx))}
            </div>
          </Panel>
        </Collapse>)}
    </>
  );
};

export default FindingCAPDetail;