const BASE_ENDPOINT_URL = process.env.REACT_APP_API_URL;
const LANDING_PAGE_API_BASE_URL = BASE_ENDPOINT_URL + "/landing-page/api";
export const SCORECARES_API_BASE_URL = BASE_ENDPOINT_URL + "/scorecards/api";
const ADMIN_API_BASE_URL = BASE_ENDPOINT_URL + "/admin/api";
const IDENTITY_API_BASE_URL = BASE_ENDPOINT_URL + "/identity/api";
export const VALUECHAIN_API_BASE_URL = BASE_ENDPOINT_URL + "/value-chain/api";
export const MFT_API_BASE_URL = BASE_ENDPOINT_URL + "/mft-scorecards/api";
export const OPERATIONAL_API_BASE_URL = BASE_ENDPOINT_URL + "/operationaldetail";
export const ENDPOINTS = {
  MICROSOFT_GRAPH_BASE_URL: "https://graph.microsoft.com",

  GET_MAP_DATA:
    LANDING_PAGE_API_BASE_URL + "/mapoverview/v1/overview?locationId=",
  GET_GHG_DATA:
    LANDING_PAGE_API_BASE_URL + "/ghgsustainability/v1/overview?locationId=",
  GET_VALUE_CHAIN_DATA: "/valuechain/v1/getvaluechaindata",
  GET_VALUE_CHAIN_DATA_OPU: "/valuechain/getvaluechaindata",
  //get value chain overview
  GET_VALUE_CHAIN_OVERVIEW: "/overview/v1/getvaluechainoverview",
  GET_VALUE_CHAIN_OVERVIEW_OPU: "/overview/getvaluechainoverview",

  GET_CARGO_DELIVERY: '/productiongeneration/v1/getcargodelivery',

  GET_CALLOUT_DATA:
    LANDING_PAGE_API_BASE_URL + "/callout/v1/getlocationcallout",
  GET_SUSTAINABILITY_CALLOUT_DATA:
    LANDING_PAGE_API_BASE_URL + "/ghgsustainability/v1/getlocationcallout",
  GET_CALLOUT_DATA_SEC:
    LANDING_PAGE_API_BASE_URL + "/callout/v2/getlocationcallout",
  GET_HIGHLIGHT_DATA:
    LANDING_PAGE_API_BASE_URL + "/callout/v1/getlocationhighlight",
  GET_CHART_DATA: LANDING_PAGE_API_BASE_URL + "/callout/v1/getchartdata",
  GET_HIS_TREND_CHART_DATA: LANDING_PAGE_API_BASE_URL + "/callout/v2/getchartdata",
  GET_PRIMARY_INFO_DATA:
    LANDING_PAGE_API_BASE_URL + "/callout/v1/getprimaryinfo",
  GET_DELIVER_HISTORY_DATA:
    LANDING_PAGE_API_BASE_URL + "/callout/v1/getdeliveriedhistories",
  GET_CARGO_DETAILS:
    LANDING_PAGE_API_BASE_URL + "/callout/v2/getcargodetails",
  GET_EXECUTIEVE_SUMMARY: "/executivesummary/v1/getexecutivesummary",
  GET_EXECUTIEVE_SUMMARY_OPU: "/executivesummary/getexecutivesummary",
  GET_GNE_TAB_BIG_CARD_HSSE: "/hsse/v1/getscorecardhssedata",
  GET_GNE_TAB_BIG_CARD_HSSE_OPU: "/hsse/getscorecardhssedata",
  GET_GNE_TAB_BIG_CARD_FINANCIAL: "/financialexcellence/v1/getprimarycards",
  GET_GNE_TAB_BIG_CARD_FINANCIAL_OPU: "/financialexcellence/getprimarycards",
  GET_GNE_TAB_BIG_CARD_PLANT: "/plantperformance/v1/getprimarycards",
  GET_GNE_TAB_BIG_CARD_PLANT_OPU: "/plantperformance/getprimarycards",
  GET_GNE_TAB_BIG_CARD_PRODUCTION: "/productiongeneration/v1/getprimarycards",
  GET_GNE_TAB_BIG_CARD_PRODUCTION_OPU: "/productiongeneration/getprimarycards",
  GET_GNE_TAB_SMALL_CARD: "/financialexcellence/v1/getsecondarycards",
  GET_GNE_TAB_SMALL_CARD_OPU: "/financialexcellence/getsecondarycards",
  GET_GNE_TAB_PERFORMANCE_SECONDARY_CARD: "/plantperformance/v1/getsecondarycards",
  GET_GNE_TAB_PRIMARY_CARD_DETAIL:
    SCORECARES_API_BASE_URL + "/financialexcellence/v1/getprimarycarddetail",
  GET_GNE_TAB_BREAKDOWN_CARD:
    SCORECARES_API_BASE_URL + "/financialexcellence/v1/getbreakdowncards",

  GET_GNE_TAB_STRATEGIC_INITIATIVES_OPU: "/strategicinitiatives",

  GET_REVENUE_CHART_HSSE:
    SCORECARES_API_BASE_URL + "/hsse/v1/getdatarevenuechart",
  GET_REVENUE_CHART_FINANCIAL: "/financialexcellence/v1/getdatarevenuechart",
  GET_REVENUE_CHART_FINANCIAL_OPU: "/financialexcellence/getdatarevenuechart",
  GET_REVENUE_CHART_PLANT: "/plantperformance/v1/getdatarevenuechart",
  GET_REVENUE_CHART_PLANT_OPU: "/plantperformance/getdatarevenuechart",
  GET_REVENUE_CHART_PRODUCTION: "/productiongeneration/v1/getdatarevenuechart",
  GET_REVENUE_CHART_PRODUCTION_OPU: "/productiongeneration/getdatarevenuechart",

  GET_REVENUE_CHART_HSSE_LVL3:
    SCORECARES_API_BASE_URL + "/hsse/v1/level3/getdatarevenuechart",
  GET_REVENUE_CHART_FINANCIAL_LVL3:
    "/financialexcellence/v1/level3/getdatarevenuechart",
  GET_REVENUE_CHART_FINANCIAL_LVL3_OPU:
    "/financialexcellence/level3/getdatarevenuechart",
  GET_REVENUE_CHART_PLANT_LVL3:
    "/plantperformance/v1/level3/getdatarevenuechart",
  GET_REVENUE_CHART_PLANT_LVL3_OPU:
    "/plantperformance/level3/getdatarevenuechart",
  GET_REVENUE_CHART_PRODUCTION_LVL3:
    "/productiongeneration/v1/level3/getdatarevenuechart",
  GET_REVENUE_CHART_PRODUCTION_LVL3_OPU:
    "/productiongeneration/level3/getdatarevenuechart",

  GET_WATERFALL_CHART_HSSE:
    SCORECARES_API_BASE_URL + "/hsse/v1/getdatawaterfallchart",
  GET_WATERFALL_CHART_FINANCIAL:
    "/financialexcellence/v1/getdatawaterfallchart",
  GET_WATERFALL_CHART_FINANCIAL_OPU:
    "/financialexcellence/getdatawaterfallchart",
  GET_WATERFALL_CHART_PLANT: "/plantperformance/v1/getdatawaterfallchart",
  GET_WATERFALL_CHART_PLANT_OPU: "/plantperformance/getdatawaterfallchart",
  GET_WATERFALL_CHART_PRODUCTION:
    "/productiongeneration/v1/getdatawaterfallchart",
  GET_WATERFALL_CHART_PRODUCTION_OPU:
    "/productiongeneration/getdatawaterfallchart",

  GET_WATERFALL_CHART_HSSE_LVL3:
    SCORECARES_API_BASE_URL + "/hsse/v1/level3/getdatawaterfallchart",
  GET_WATERFALL_CHART_FINANCIAL_LVL3:
    "/financialexcellence/v1/level3/getdatawaterfallchart",
  GET_WATERFALL_CHART_FINANCIAL_LVL3_OPU:
    "/financialexcellence/level3/getdatawaterfallchart",
  GET_WATERFALL_CHART_PLANT_LVL3:
    "/plantperformance/v1/level3/getdatawaterfallchart",
  GET_WATERFALL_CHART_PLANT_LVL3_OPU:
    "/plantperformance/level3/getdatawaterfallchart",
  GET_WATERFALL_CHART_PRODUCTION_LVL3:
    "/productiongeneration/v1/level3/getdatawaterfallchart",
  GET_WATERFALL_CHART_PRODUCTION_LVL3_OPU:
    "/productiongeneration/level3/getdatawaterfallchart",

  GET_GNE_TAB_PRIMARY_CARD_FINANCIAL_DETAIL:
    "/financialexcellence/v1/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_FINANCIAL_DETAIL_OPU:
    "/financialexcellence/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_PLANT_DETAIL:
    "/plantperformance/v1/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_PLANT_DETAIL_OPU:
    "/plantperformance/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_PRODUCTION_DETAIL:
    "/productiongeneration/v1/getdataexecutive",
  GET_GNE_TAB_PRIMARY_CARD_PRODUCTION_DETAIL_OPU:
    "/productiongeneration/getdataexecutive",

  GET_GNE_TAB_BREAKDOWN_CARD_FINANCIAL:
    "/financialexcellence/v1/getbreakdowncards",
  GET_GNE_TAB_BREAKDOWN_CARD_FINANCIAL_OPU:
    "/financialexcellence/getbreakdowncards",
  GET_GNE_TAB_BREAKDOWN_CARD_PLANT: "/plantperformance/v1/getbreakdowncards",
  GET_GNE_TAB_BREAKDOWN_CARD_PLANT_OPU: "/plantperformance/getbreakdowncards",
  GET_GNE_TAB_BREAKDOWN_CARD_PRODUCTION:
    "/productiongeneration/v1/getdatabreakdown",
  GET_GNE_TAB_BREAKDOWN_CARD_PRODUCTION_OPU:
    "/productiongeneration/getdatabreakdown",

  GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_HSSE:
    SCORECARES_API_BASE_URL + "/hsse/v1/level3/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_FINANCIAL:
    "/financialexcellence/v1/level3/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_FINANCIAL_OPU:
    "/financialexcellence/level3/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PLANT:
    "/plantperformance/v1/level3/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PLANT_OPU:
    "/plantperformance/level3/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PRODUCTION:
    "/productiongeneration/v1/level3/getprimarycarddetail",
  GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PRODUCTION_OPU:
    "/productiongeneration/level3/getprimarycarddetail",

  GET_GNE_TAB_BREAKDOWN_CARD_LV3_PLANT:
    "/plantperformance/v1/level3/getbreakdowncards",
  GET_GNE_TAB_BREAKDOWN_CARD_LV3_PLANT_OPU:
    "/plantperformance/level3/getbreakdowncards",
  GET_GNE_TAB_BREAKDOWN_CARD_LV3_PRODUCTION:
    "/productiongeneration/v1/level3/getbreakdowncards",
  GET_GNE_TAB_BREAKDOWN_CARD_LV3_PRODUCTION_OPU:
    "/productiongeneration/level3/getbreakdowncards",

  GET_LMT_OPERATIONAL_PERFORMANCE_SECONDARY_CARD_DETAIL:
    "/operationaldetail/getoperationaldetaildata",
  GET_LMT_SALES_VOLUME_CHART_DATA:
    "/operationaldetail/getlmtchartdatanew",
  GET_LMT_SALES_COUNTRY_DATA:
    "/operationaldetail/getlistcountrycustomerchart",
  GET_LMT_CONTRACT_DETAIL_TABLE:
    "/operationaldetail/getlmtcontractdetailtable",
  GET_LMT_HSSE_PERFORMANCE_SECONDARY_CARD_DETAIL:
    "/hsse/getscorecardhssedatadetail",
  GET_LMT_OPERATIONAL_PERFORMANCE_EXEC_SUMMARY:
    "/productiongeneration/getexecutivesummaryparameters",
  GET_MLNG_FINANCIAL_EXCELLENCE_EXEC_SUMMARY:
    "/financialexcellence/getexecutivesummaryparameters",
  GET_MLNG_HSE_EXEC_SUMMARY:
    "/hsse/getexecutivesummaryparameters",
  GET_MLNG_PLANT_PERFOMANCE_EXEC_SUMMARY:
    "/plantperformance/getexecutivesummaryparameters",
  GET_LMT_FINANCIAL_EXCELLENCE_SECONDARY_CARD_DETAIL:
    "/financialexcellence/getcardcapexdetail",
  GET_LMT_CONTRACT_DETAIL:
    "/operationaldetail/getlmtcontractdetailtable",
  GET_LMT_SALE_VOLUME:
    "/operationaldetail/getsalevolumechart",


  GET_GNE_PERIODS_LIST: "/data/v1/getperiodslist",
  GET_GNE_PERIODS_LIST_OPU: "/data/getperiodslist",

  GNE_DELETE_UPLOAD_FILE: "/v1/uploadfile/scorecardmanagement/removehistoryupload",
  GNE_DELETE_UPLOAD_FILE_OPU: "/uploadfile/scorecardmanagement/removehistoryupload",

  GNE_PREVIEW_UPLOAD_FILE: "/v1/uploadfile/scorecardmanagement/geturlfiles3",
  GNE_PREVIEW_UPLOAD_FILE_OPU: "/uploadfile/scorecardmanagement/geturlfiles3",

  GNE_DOWNLOAD_UPLOAD_FILE: "/v1/uploadfile/scorecardmanagement/downloadfiles3",
  GNE_DOWNLOAD_UPLOAD_FILE_OPU: "/uploadfile/scorecardmanagement/downloadfiles3",
  
  VALIDATE_SCORECARD_FILE: "/v1/uploadfile/scorecardmanagement/validateexcel",
  UPLOAD_SCORECARD_FILE: "/v1/uploadfile/scorecardmanagement/importexcel",
  UPLOAD_SCORECARD_FILET_OPU: "/uploadfile/scorecardmanagement/importexcel",
  GET_SCORE_CARD_LIST: "/v1/uploadfile/scorecardmanagement/getfoldersuploadstatus",
  GET_SCORE_CARD_LIST_OPU: "/uploadfile/scorecardmanagement/getfoldersuploadstatus",
  GET_SCORE_CARD_LIST_LIST_HISTORY_UPLOAD: "/v1/uploadfile/scorecardmanagement/getlisthistoryupload",
  GET_SCORE_CARD_LIST_LIST_HISTORY_UPLOAD_OPU: "/uploadfile/scorecardmanagement/getlisthistoryupload",
  GET_SCORE_CARD_LIST_HISTORY_ERROR: "/v1/uploadfile/scorecardmanagement/getlisthistoryerror",

  INIT_MULTIPART_UPLOAD: "/v1/uploadfile/scorecardmanagement/initmultipartupload",
  INIT_MULTIPART_UPLOAD_OPU: "/uploadfile/scorecardmanagement/initmultipartupload",

  COMPLETE_MULTIPART_UPLOAD: "/v1/uploadfile/scorecardmanagement/completeexcelmultipart",
  COMPLETE_MULTIPART_UPLOAD_OPU: "/uploadfile/scorecardmanagement/completeexcelmultipart",


  GET_PRIVATE_ROUTE_IS_AUTHORIZED:
    LANDING_PAGE_API_BASE_URL + "/authorization/v1/isuserauthorized",
  GET_VALIDATE_USER: IDENTITY_API_BASE_URL + "/user/v1/validate",

  GET_USERMANAGEMENT_USERS: ADMIN_API_BASE_URL + "/users/v1",
  GET_USERMANAGEMENT_OPUS: ADMIN_API_BASE_URL + "/opus/v1",
  GET_USERMANAGEMENT_PERSONAS: ADMIN_API_BASE_URL + "/personas/v1",
  GET_USERMANAGEMENT_ROLES: ADMIN_API_BASE_URL + "/accessroles/v1",
  PUT_USERMANAGEMENT_USER: ADMIN_API_BASE_URL + "/users/v1/",
  POST_USERMANAGEMENT_CREATE_USER: ADMIN_API_BASE_URL + "/users/v1",
  GET_USERMANAGEMENT_PERSONA_TABLE: ADMIN_API_BASE_URL + "/views/v1",
  GET_USERMANAGEMENT_WM_TABLE: ADMIN_API_BASE_URL + "/views/v1/maps",
  GET_USERMANAGEMENT_ADD_MAP_LIST: ADMIN_API_BASE_URL + "/views/v1/maps",
  POST_USERMANAGEMENT_UPDATE_PERMISSION:
    ADMIN_API_BASE_URL + "/views/v1/maps/mapsconfig/",
  GET_USERMANAGEMENT_PERSONA_LIST: ADMIN_API_BASE_URL + "/views/v1/",
  POST_USERMANAGEMENT_PERSONA_LIST: ADMIN_API_BASE_URL + "/views/v1/",
  GET_USERMANAGEMENT_DOWNLOAD: ADMIN_API_BASE_URL + "/users/v1/download",
  GET_USERMANAGEMENT_STATUS: ADMIN_API_BASE_URL + "/users/v1/statuses",
  GET_USERMANAGEMENT_SORTTYPE: ADMIN_API_BASE_URL + "/users/v1/",
  GET_LIMITCOFIG_WM_TABLE: ADMIN_API_BASE_URL + "/limitconfig/v1/worldmap",
  GET_LIMITCONFIG_VC_TABLE: ADMIN_API_BASE_URL + "/limitconfig/v1/valuechain",
  POST_LIMITCONFIG_RULES: ADMIN_API_BASE_URL + "/limitconfig/v1",

  GET_SCORECARDORDER_LIST: ADMIN_API_BASE_URL + "/scorecardordering/v1",
  POST_SCORECARDORDER_LIST: ADMIN_API_BASE_URL + "/scorecardordering/v1",
  GET_SCORECARDORDER_OPUS: ADMIN_API_BASE_URL + "/scorecardordering/v1/opus",

  GET_MFT_EXECSUMMARY: "/v1/gne/executivesummary",
  GET_MFT_EXECSUMMARY_OPU: "/executivesummary",
  GET_MFT_COMMON: "/common/getsubtabs",
  GET_MFT_LINE_GNE: "/v1/gne/value/getlinechart",
  GET_MFT_LINE_OPU: "/value/getlinechart",
  GET_MFT_CARD_GNE: "/v1/gne/value/getprimarycards",
  GET_MFT_CARD_OPU: "/value/getprimarycards",
  GET_MFT_PERIOD_LIST: "/v1/gne/data/getperiodslist",
  GET_MFT_PERIOD_LIST_OPU: "/data/getperiodslist",

  GET_MFT_LINE_GROWTH_GNE: "/v1/gne/growth/getlinechart",
  GET_MFT_LINE_GROWTH_OPU: "/growth/getlinechart",
  GET_MFT_CARD_GROWTH_GNE: "/v1/gne/growth/getprimarycards",
  GET_MFT_CARD_GROWTH_OPU: "/growth/getprimarycards",

  GET_MFT_EMISSION_CARDS_GNE: "/v1/gne/ghgemissions/getprimarycards",
  GET_MFT_EMISSION_CARDS_OPU: "/ghgemissions/getprimarycards",

  GET_MFT_EMISSION_CHARTS_GNE: "/v1/gne/ghgemissions/getwavechart",
  GET_MFT_EMISSION_CHARTS_OPU: "/ghgemissions/getwavechart",

  GET_MFT_REDUCTION_CARDS_GNE: "/v1/gne/ghgreduction/getprimarycards",
  GET_MFT_REDUCTION_CARDS_OPU: "/ghgreduction/getprimarycards",

  GET_MFT_INTENSITY_CARDS_GNE: "/v1/gne/ghgintensity/getprimarycards",
  GET_MFT_INTENSITY_CARDS_OPU: "/ghgintensity/getprimarycards",

  GET_MFT_INTENSITY_CHARTS_GNE: "/v1/gne/ghgintensity/getlinechart",
  GET_MFT_INTENSITY_CHARTS_OPU: "/ghgintensity/getlinechart",

  GET_MFT_SUSTAIN_CARDS: "/sustainability/getprimarycards",
  GET_MFT_SUSTAIN_LINE_CHART: "/sustainability/getlinechart",
  GET_MFT_SUSTAIN_WAVE_CHART: "/sustainability/getwavechart",

  GET_FORWARD_PRICE:
    LANDING_PAGE_API_BASE_URL + "/priceoutlook/v1/getmarkerspricedata",
  GET_CONTENT_RELEASE_ANNOUNCEMENT:
    LANDING_PAGE_API_BASE_URL + "/announcement/v1/getannouncement",
  GET_RC_TABLE_DATA: ADMIN_API_BASE_URL + "/v1/announcements",
  RC_TOGGLE_PUBLISH: ADMIN_API_BASE_URL + "/v1/announcements/togglepublish",

  GET_OPUS_USER_ACCESS: ADMIN_API_BASE_URL + "/accessrequest/v1/opus",
  GET_ADMIN_USER_ACCESS: ADMIN_API_BASE_URL + "/accessrequest/v1/admins",
  SEND_USER_ACCESS: ADMIN_API_BASE_URL + "/accessrequest/v1/newuser",
  GET_REQUEST_STATUS: IDENTITY_API_BASE_URL + "/user/v1/getuserrequestedinfor",
  POST_LOGIN_HISTORY: IDENTITY_API_BASE_URL + "/user/v1/addloginhistory",

  GET_DATA_PIPELINES: ADMIN_API_BASE_URL + "/v1/datahealth/getdatahealth",

  // SUSTAINABILITY
  POST_SUSTAINABILITY_SUMMARY:
    LANDING_PAGE_API_BASE_URL + "/ghgsustainability/v1/updateghgsummarycallout",
  
  GET_MFT_SUSTAINABILITY_DRILLDOWN_FPLNG: 
    LANDING_PAGE_API_BASE_URL + '/ghgsustainability/v1/getdrilldownpflng',

  GET_MFT_SUSTAINABILITY_DRILLDOWN_RGTP: 
    LANDING_PAGE_API_BASE_URL + '/ghgsustainability/v1/getdrilldownrgtp',

  GET_MFT_SUSTAINABILITY_DRILLDOWN_RGTSU: 
    LANDING_PAGE_API_BASE_URL + '/ghgsustainability/v1/getdrilldownrgtsu',

  GET_MFT_SUSTAINABILITY_DRILLDOWN_GT: 
    LANDING_PAGE_API_BASE_URL + '/ghgsustainability/v1/getdrilldowngt',

  // RISK
  // RISK PROFILE
  GET_RISK_PROFILE:
    LANDING_PAGE_API_BASE_URL + "/riskmanagement/v1/getriskprofiles",

  GET_RISK_AVAILABLE_PERIOD:
    LANDING_PAGE_API_BASE_URL + "/riskmanagement/v1/getavailableperiod",

  GET_RISK_MITIGATION_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/riskmanagement/v1/getriskprofilemitigationcallout",

  GET_RISK_KRI_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/riskmanagement/v1/getriskprofilekricallout",

  // RISK MYASSURANCE
  GET_GAS_BUSINESS_FLA:
    LANDING_PAGE_API_BASE_URL + "/myassurance/v1/getfla",

  GET_GAS_BUSINESS_TLA:
    LANDING_PAGE_API_BASE_URL + "/myassurance/v1/gettla",

  GET_LNGA_FLA:
    LANDING_PAGE_API_BASE_URL + "/lnga/myassurance/v1/getfla",

  GET_LNGA_TLA:
    LANDING_PAGE_API_BASE_URL + "/lnga/myassurance/v1/gettla",

  GET_GP_FLA:
    LANDING_PAGE_API_BASE_URL + "/gp/myassurance/v1/getfla",

  GET_GP_TLA:
    LANDING_PAGE_API_BASE_URL + "/gp/myassurance/v1/gettla",

  GET_LMT_FLA:
    LANDING_PAGE_API_BASE_URL + "/lmt/myassurance/v1/getfla",

  GET_GCO_SLA:
    LANDING_PAGE_API_BASE_URL + "/gco/myassurance/v1/getsla",

  GET_GAS_BUSINESS_SLA:
    LANDING_PAGE_API_BASE_URL + "/myassurance/v1/getsla",

  GET_LNGA_SLA:
    LANDING_PAGE_API_BASE_URL + "/lnga/myassurance/v1/getsla",

  GET_GP_SLA:
    LANDING_PAGE_API_BASE_URL + "/gp/myassurance/v1/getsla",

  GET_LMT_SLA:
    LANDING_PAGE_API_BASE_URL + "/lmt/myassurance/v1/getsla",

  GET_GCO_SLA:
    LANDING_PAGE_API_BASE_URL + "/gco/myassurance/v1/getsla",

  GET_LMT_TLA:
    LANDING_PAGE_API_BASE_URL + "/lmt/myassurance/v1/gettla",

  GET_GCO_FLA:
    LANDING_PAGE_API_BASE_URL + "/gco/myassurance/v1/getfla",

  GET_GCO_TLA:
    LANDING_PAGE_API_BASE_URL + "/gco/myassurance/v1/gettla",

  GET_LNGA_ASSURANCE_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/lnga/myassurance/v1/getassetcallout",

  GET_LMT_ASSURANCE_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/lmt/myassurance/v1/getassetcallout",

  GET_GCO_ASSURANCE_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/gco/myassurance/v1/getassetcallout",

  GET_GP_ASSURANCE_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/gp/myassurance/v1/getassetcallout",


 GET_LNGA_ASSURANCE_SCHEDULE_COMP_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/lnga/myassurance/v1/getschedulecomplianceassetcallout",

  GET_LMT_ASSURANCE_SCHEDULE_COMP_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/lmt/myassurance/v1/getschedulecomplianceassetcallout",

  GET_GCO_ASSURANCE_SCHEDULE_COMP_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/gco/myassurance/v1/getschedulecomplianceassetcallout",

  GET_GP_ASSURANCE_SCHEDULE_COMP_CALLOUT:
    LANDING_PAGE_API_BASE_URL + "/gp/myassurance/v1/getschedulecomplianceassetcallout",
};

export const IGNORE_URL_COUNT_REQUEST = [
  ENDPOINTS.GET_CHART_DATA,
  ENDPOINTS.GET_CALLOUT_DATA,
  ENDPOINTS.GET_HIGHLIGHT_DATA,
  ENDPOINTS.GET_PRIMARY_INFO_DATA,
  ENDPOINTS.GET_DELIVER_HISTORY_DATA,
  ENDPOINTS.GET_REVENUE_CHART_HSSE,
  ENDPOINTS.GET_REVENUE_CHART_FINANCIAL,
  ENDPOINTS.GET_REVENUE_CHART_PLANT,
  ENDPOINTS.GET_REVENUE_CHART_PRODUCTION,
  ENDPOINTS.GET_REVENUE_CHART_HSSE_LVL3,
  ENDPOINTS.GET_REVENUE_CHART_FINANCIAL_LVL3,
  ENDPOINTS.GET_REVENUE_CHART_PLANT_LVL3,
  ENDPOINTS.GET_REVENUE_CHART_PRODUCTION_LVL3,
  ENDPOINTS.GET_WATERFALL_CHART_HSSE,
  ENDPOINTS.GET_WATERFALL_CHART_FINANCIAL,
  ENDPOINTS.GET_WATERFALL_CHART_PLANT,
  ENDPOINTS.GET_WATERFALL_CHART_PRODUCTION,
  ENDPOINTS.GET_WATERFALL_CHART_HSSE_LVL3,
  ENDPOINTS.GET_WATERFALL_CHART_FINANCIAL_LVL3,
  ENDPOINTS.GET_WATERFALL_CHART_PLANT_LVL3,
  ENDPOINTS.GET_WATERFALL_CHART_PRODUCTION_LVL3,
  ENDPOINTS.GET_USERMANAGEMENT_OPUS,
  ENDPOINTS.GET_USERMANAGEMENT_PERSONAS,
  ENDPOINTS.GET_USERMANAGEMENT_ROLES,
  ENDPOINTS.MICROSOFT_GRAPH_BASE_URL,
  ENDPOINTS.GET_USERMANAGEMENT_PERSONA_LIST,
  ENDPOINTS.POST_LOGIN_HISTORY,
  ENDPOINTS.GET_HIS_TREND_CHART_DATA,
  ENDPOINTS.GET_CALLOUT_DATA_SEC,
  ENDPOINTS.GET_DATA_PIPELINES
];

export const STATUS = {
  ERROR: 401,
  CREATED: 201,
  OK: 200,
  UPDATE: 204,
};

export const STATUS_STR = {
  ERROR: 'ERROR',
  CREATED: 'CREATED',
  OK: 'OK',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
};
