import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import s from '../AssuranceLines.module.css';
import { fetchGasBusinessSLA, fetchGCOSLA, fetchGPSLA, fetchLMTSLA, fetchLNGASLA } from "../../../../action/actionRisk";
import SecondLineGBTableComponent from '../AssuranceSecondLine/SecondLineGBTableComponent';
import SecondLineActionItemComponent from '../AssuranceSecondLine/SecondLineActionItemComponent';

export interface AssuranceLineProps {
  data: {
    opu: number;
    line: number;
  };
  onChangeTab: (tab: number) => void;
  opuTabArr: { label: string, value: number }[];
  fromDate: {
    year: number;
    month: number;
  };
  toDate: {
    year: number;
    month: number;
  }
}

const AssuranceSecondLineComponent = ({ data, onChangeTab, opuTabArr, fromDate, toDate }: AssuranceLineProps) => {
  const dispatch = useDispatch();
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    setHasData(false); // Clear data when OPU changes
    switch (data.opu) {
      case 0:
        dispatch(fetchGasBusinessSLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 1:
        dispatch(fetchLNGASLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 2:
        dispatch(fetchGPSLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 3:
      dispatch(fetchLMTSLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
      setHasData(true);
        break;
      case 4:
        dispatch(fetchGCOSLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      default:
        break;
    }
  }, [dispatch, data.opu, data.line, fromDate, toDate]);

  const CAPData = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceCorrectiveActionPlan', [])
  );

  const assuranceProgramData = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceProgram', [])
  );

  const renderContent = (type: string) => {
    const info = type === "CAP" ? CAPData : assuranceProgramData;
    if (!hasData || !info || info.length === 0) {
      return <div className={s.noResult}>
      <img src="/img/icon/no-result.svg" alt="No results" />
      <h2>No data available!</h2>
      </div>;
    }
    return (
      <div className={s.contentContainer}>
        <div className={s.flexContainer}>
          <SecondLineActionItemComponent type={type}/>
        </div>
        <SecondLineGBTableComponent
          assuranceData={info}
          opu={data.opu}
          onChangeTab={onChangeTab}
          opuTabArr={opuTabArr}
          type={type}
          fromDate={fromDate}
          toDate={toDate}
        />
      </div>
    );
  };

  const renderBase = () => {
    return (
      <>
        <div className={s.requirement}>
          <div className={s.header}>
            <span>Corrective Action Plan (CAP)</span>
          </div>
          {renderContent("CAP")}
        </div>

        <div className={s.schedule}>
          <div className={s.header}>
            <span>Assurance Program</span>
          </div>
          {renderContent("assuranceProgram")}
        </div>
      </>
    );
  };

  return renderBase();
};

export default AssuranceSecondLineComponent;