import { useSelector } from 'react-redux';
import { get } from 'lodash';
import s from '../../../container/RiskManagement/MyAssurance/AssuranceLines.module.css';
import { Progress } from 'antd';

export interface FirstLineActionItemProps {
	overall?: number;
	type?: string;
}

const OverallChart = ({ type, overall }: FirstLineActionItemProps) => {
	const requirementInfo = useSelector((state) =>
		get(state, 'risk.myAssuranceInfo.result.assuranceRequirementCompliance', [])
	  );
	
	const scheduleInfo = useSelector((state) =>
	get(state, 'risk.myAssuranceInfo.result.assuranceScheduleCompliance', [])
	);

	const getCircleColorClass = (value) => {
		if (value > 80) return '#00FF5D';
		if (value > 50 && value <= 80) return '#FDB924';
		return '#DA2228';
	};
	
	const getDotColorClass = (value) => {
		if (value > 80) return s.greenDot;
		if (value > 50 && value <= 80) return s.yellowDot;
		if (value == 0) return s.greyDot;
		return s.redDot;
	};

	const getOverallLabel = (value) => {
		if (value > 80) return "Effective";
		if (value > 50 && value <= 80) return "Some Improvement Needed";
		if (value == 0) return "No Data";
		return "Major Improvement Needed";
	};

	const overallPercentage = overall !== undefined && overall !== null ? overall : type === 'requirement' ? requirementInfo?.overallPercentage?.value : scheduleInfo?.overallPercentage?.value;
	const circleColorClass = getCircleColorClass(overallPercentage);
	const dotColorClass = getDotColorClass(overallPercentage);
	const overallLabel = getOverallLabel(overallPercentage);

	return (
		<div className={s.overallSection}>
			<div className={s.overallHeader}>
				Overall
			</div>
			<div className={s.infoLabel}>
				<span className={`${s.dot} ${dotColorClass}`}></span>
				<span style={{opacity: '0.6'}}>{overallLabel}</span>
				<div className={s.tooltipWrapper}>
					<img src='/img/icon/ic_info_outline.svg' alt="Requirement Tooltip" className={s.infoIcon} />
					<div className={s.headerTooltipContainer}>
						<img src="img/tooltip/overall-circle-tooltip.svg" alt="Requirement Tooltip" />
					</div>
				</div>
			</div>
			<div className={`${s.overallCircle} ${circleColorClass}`}>
				<Progress
					type="circle"
					className={s.percentage}
					percent={overallPercentage}
					strokeColor={circleColorClass}
					trailColor="#365F74"
					format={() => <p>{overallPercentage}%</p>}
					width={90}
					strokeWidth={7}
				/>
			</div>
		</div>
	);
};

export default OverallChart;