import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Collapse, Progress } from 'antd';
import s from './ChecklistDetailsAccordion.module.css';
import { useState } from 'react';
import * as _ from 'lodash';

const ChecklistDetailsAccordion = () => {
	const { Panel } = Collapse;
	const [activeKeys, setActiveKeys] = useState<string[]>([]);

	const accordionData = useSelector((state) =>
		get(state, 'risk.assuranceAssetCallout.result.checkListDetails', [])
	);

	const statusCount = ({ overdueVal }: { overdueVal: number }) => {
		return ( 
			<span className={s.accordionHeader}>{overdueVal} Overdue</span>
		);
	};

	const renderChildrenAccordion = (findings) => {
		findings = findings.map(fd => ({
			...fd,
			checkListItems: fd.checkListItems.map(clI => ({
				...clI,
				findingDescription: fd.findingDescription
			}))
		}));
		
		const groupByChecklistName = _.groupBy(_.flatMap(findings, 'checkListItems'),'checkListName');
		const groupByFindingDesc = Object.keys(groupByChecklistName).map(checkListName => ({
			checkListName,
			findings: _.groupBy(groupByChecklistName[checkListName], 'findingDescription')
		}))
		if (!findings || findings.length === 0) {
			return <p>No data available</p>;
		}
		
		return (
			<>
				{groupByFindingDesc.map((checkList, checkListIdx) => (
					<Collapse
						key={checkList.checkListName+checkListIdx}
						className={s.childrenCollapse}
						expandIconPosition="right"
						expandIcon={({ isActive }) => (
							<img
								alt="arrow"
								src="/img/icon/accordion-arrow-down.svg"
								className={isActive ? s.arrowActive : s.arrow}
							/>
						)}
					>
						<Panel
							header={
								<div className={s.childPanelHeader}>
										<span className={s.childPanelSubHeader}>
											Checklist Name
										</span>
										<span className={s.checkListName}>
											{checkList.checkListName}
										</span>
								</div>
							}
							key={checkList.checkListName+checkListIdx}
						>
							{Object.keys(checkList.findings).map((findingDesc, itemIdx) => (
								<div className={s.findingsContainer} key={findingDesc+itemIdx}>
									<div className={s.findingBody}>
										<span className={s.childPanelSubHeader}>
											#{itemIdx+1} Finding
										</span>
										<span className={s.actionDescription}>{findingDesc}</span>
										{checkList.findings[findingDesc].map((actionItem, actionItemIdx) => (
											<div className={s.findingActionItemContainer} key={"actionItem"+actionItemIdx}>
												<span className={s.childPanelSubHeader}>
													#{actionItemIdx+1} Action Item
													<span style={{color: 'white'}}>
														{new Date(actionItem.dueDate).toLocaleDateString('en-GB', {
															year: 'numeric',
															month: 'short',
															day: 'numeric',
														})}
													</span>
												</span>
												
												<hr/>
												<span className={s.actionDescription}>{actionItem.actionDescription}</span>
											</div>
										))}
									</div>
								</div>
							))}
						</Panel>
					</Collapse>
				))}
			</>
		);
	};

	const handlePanelChange = (keys) => {
		setActiveKeys(keys as string[]);
	};

	return (
		<>
			{accordionData.length === 0 ? (
				<div className={s.noResult}>
					<img src="/img/icon/no-result.svg" alt="No results" />
					<h2>No data available!</h2>
				</div>
			) : (
				accordionData.map((item, idx) => (
					<Collapse
						className={s.parentCollapse}
						expandIconPosition="right"
						expandIcon={({ isActive }) => (
							!item.findings?.length ? <img
								className={isActive ? `${s.arrowActive}` : `${s.arrow}`}
								alt="arrow"
								src="/img/icon/accordion-arrow-down.svg"
							/> : <></>
						)}
						activeKey={activeKeys}
						onChange={(key) => {
							if (!item.findings?.length) {
								handlePanelChange(key);
							}
						}}
					>
						<Panel
							header={
								<div className={s.panelHeader}>
									<span className={s.accordionHeader}>{item.assuranceCategory}</span>
									{statusCount({
										overdueVal: item.actionItems.overdueAction,
									})}
								</div>
							}
							key={idx}
						>
							<div className={activeKeys.includes(idx) ? s.expandedContent : ''}>
								{renderChildrenAccordion(item.findings)}
							</div>
						</Panel>
					</Collapse>
				))
			)}
		</>
	);	
};
 
export default ChecklistDetailsAccordion;