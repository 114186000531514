import { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import { Desktop } from '../../responsive/responesive';
import { Select, Space, Tooltip } from 'antd';
import s from './AssuranceCallout.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGCOAssetCallout, fetchGPAssetCallout, fetchLMTAssetCallout, fetchLNGAAssetCallout } from '../../action/actionRisk';
import { get } from 'lodash';
import FLAAssuranceCallout from './AssuranceCallout/FLAAssuranceCallout';
import SLAAssuranceCallout from './AssuranceCallout/SLAAssuranceCallout';
import TLAAssuranceCallout from './AssuranceCallout/TLAAssuranceCallout';
import { CalloutType } from '../../saga/risk.saga';

interface IAssuranceCalloutProps {
	record: any;
	onClose: any;
	opu: any;
	selectedLineTabProp?: number;
	selectedCompTabProp?: number;
	fromDate: {
		year: number;
		month: number;
	};
	toDate: {
		year: number;
		month: number;
	}
}

const AssuranceCallout = ({ record, onClose, opu, selectedLineTabProp, fromDate, toDate, selectedCompTabProp }: IAssuranceCalloutProps) => {  
	const [showAssetFilter, setShowAssetFilter] = useState(false);
	const [selectedLineTab, setSelectedLineTab] = useState<number>(selectedLineTabProp ?? 0);
	const [selectedAsset, setSelectedAsset] = useState(record.assetName);
	const [isExiting, setIsExiting] = useState(false);
	const calloutRef = useRef(null);
	const dispatch = useDispatch();
	const [selectedCompTab, setSelectedCompTab] = useState<CalloutType>(selectedCompTabProp ?? 0);

	useEffect(() => {
		if (selectedAsset) {
			switch (opu) {
				case 1:
					dispatch(fetchLNGAAssetCallout(`?assetName=${encodeURIComponent(selectedAsset)}${selectedLineTab === 0 ? `&fromMonth=${fromDate.month}&toMonth=${toDate.month}` : ''}`, selectedLineTab, selectedCompTab));
					break;
				case 2:
					dispatch(fetchGPAssetCallout(`?assetName=${encodeURIComponent(selectedAsset)}${selectedLineTab === 0 ? `&fromMonth=${fromDate.month}&toMonth=${toDate.month}` : ''}`, selectedLineTab, selectedCompTab));
					break;
				case 3:
					dispatch(fetchLMTAssetCallout(`?assetName=${encodeURIComponent(selectedAsset)}${selectedLineTab === 0 ? `&fromMonth=${fromDate.month}&toMonth=${toDate.month}` : ''}`, selectedLineTab, selectedCompTab));
					break;
				case 4:
					dispatch(fetchGCOAssetCallout(`?assetName=${encodeURIComponent(selectedAsset)}${selectedLineTab === 0 ? `&fromMonth=${fromDate.month}&toMonth=${toDate.month}` : ''}`, selectedLineTab, selectedCompTab));
					break;
				default:
					break;
			}
		}
	}, [dispatch, opu, selectedAsset, selectedLineTab, selectedCompTab]);

	const calloutData = useSelector((state) =>
		get(state, 'risk.assuranceAssetCallout.result', [])
	);

	const assetDropdown = useSelector((state) =>
		get(state, 'risk.myAssuranceInfo.assets', [])
	);

	const handleChangeLineTab = (tab: number) => {
		setSelectedLineTab(tab);
	};

	const handleChangeAsset = (value) => {
		setSelectedAsset(value);
	};

	const calloutClassName = `${s.calloutContent}`;

	const assuranceLineTabArr = [
		{ label: "FLA", value: 0 },
		{ label: "SLA", value: 1 },
		{ label: "TLA", value: 2 },
	];

	const handleClose = () => {
		setIsExiting(true);
		setTimeout(() => {
			onClose();
			setIsExiting(false);
		}, 300);
	};

	const CloseButton = useMemo(() => (
		<button
			type='button'
			onClick={handleClose}
			className={s.callOutCloseBtn}
		>
			<img alt='Close' src='/img/icon/ic-close-secondary.svg' />
		</button>
	), [])

	function containerClassName(cssModule) {
	return `${cssModule.assuranceCalloutContainer} ${cssModule.show}`;
	}

	const showAssetFilterHandle = useCallback(() => setShowAssetFilter((prev) => !prev), [])

	return (
	<>
		<div>
			<div className={`${s.overlay} ${isExiting ? s.fadeOut : s.fadeIn}`}></div>
			<Desktop>
				<div ref={calloutRef} className={`${containerClassName(s)} ${s.assuranceCalloutContainer} ${isExiting ? s.slideOut : s.slideIn}`}>
					<div className={calloutClassName}>
						<div className={s.calloutHeader}>
							<div className={s.headerTitle}>
								<div className={s.filterContainer}>
									<div className={s.filterBlocks}>
										<div className={s.filterBlock}>
											<span>ASSET</span>
											<div
												className={s.filterBlockDropdown}
													onBlur={(e) => {
														if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
															setShowAssetFilter(false);
														}
													}}
											>
													<Tooltip title={selectedAsset} placement="bottomRight">
														<button
															type="button"
															className={s.dropdownButton}
															onClick={showAssetFilterHandle}
														>
															{selectedAsset}
															<i className='mdi mdi-menu-down'></i>
														</button>
													</Tooltip>
													{showAssetFilter && (
													<div className={s.assetSelect}>
														<Space direction="vertical">
															<Select
																className={s.mapRightPanelSelect}
																value={selectedAsset}
																onChange={handleChangeAsset}
																open={showAssetFilter}
																onDropdownVisibleChange={(open) => setShowAssetFilter(open)}
																dropdownStyle={{
																	padding: '0',
																	background: 'linear-gradient(180deg,rgba(6, 25, 25, 0.9) 0%,#000000 100%)',
																}}
															>
																{assetDropdown.map((item, idx) => (
																	<Select.Option
																		value={item.assetName}
																		key={item.assetName}
																		className="score-card-OPU-selection-item"
																	>
																		{item.assetName}
																	</Select.Option>
																))}
															</Select>
														</Space>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
									{CloseButton}
							</div>
						</div>
						<div className={s.opuTab}>
							<ul>
								{assuranceLineTabArr.map((v, i) => {
									const isDisabled = false;
									return (
										<li
											key={i}
											onClick={() => {
												if (!isDisabled) handleChangeLineTab(v.value);
											}}
											onKeyDown={(e) => {
												if (!isDisabled && e.key === 'Enter') {
													handleChangeLineTab(v.value);
												}
											}}
											tabIndex={isDisabled ? -1 : 0}
											className={`${selectedLineTab === v.value ? s.active : ''} ${isDisabled ? s.disabled : ''}`}
										>
											{v.label}
										</li>
									);
								})}
							</ul>
						</div>
						<div className={s.sustainabilityCallOutBody}>
								{selectedLineTab === 0 && <FLAAssuranceCallout selectedCompTab={selectedCompTab ?? 0} setSelectedCompTab={setSelectedCompTab} calloutData={calloutData} />}
								{selectedLineTab === 1 && <SLAAssuranceCallout calloutData={calloutData} />}
								{selectedLineTab === 2 && <TLAAssuranceCallout calloutData={calloutData} />}
						</div>
					</div>
				</div>
			</Desktop>
		</div>
	</>
	);
};
export default AssuranceCallout;
