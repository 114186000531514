import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { 
  getAvailablePeriod,
  getRiskTop,
  getRiskKRICallout,
  getRiskMitigationCallout,
  getGasBusinessFLA,
  getLNGAFLA,
  getLMTFLA,
  getGCOFLA,
  getGPFLA,
  getGasBusinessSLA,
  getLNGASLA,
  getLMTSLA,
  getGCOSLA,
  getGPSLA,
  getGasBusinessTLA,
  getLNGATLA,
  getLMTTLA,
  getGCOTLA,
  getGPTLA,
  getLNGAAssetCallout,
  getLMTAssetCallout,
  getGCOAssetCallout,
  getGPAssetCallout,

} from '../services/risk.service';

export enum RiskActionLine {
  fla = 0,
  sla = 1,
  tla = 2,
}

export enum CalloutType {
  Other = 0,
  ScheduleCompliance = 1
}

interface RiskAction extends Action {
  data: string | any;
  payload: {} | any;
  line: RiskActionLine | undefined;
  calloutType?: CalloutType;
}

function* fetchRiskTop(action?: RiskAction) {
  try {
    const response = yield call(getRiskTop, action?.payload)
    yield put({type: types.GET_RISK_TOP_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_TOP_FAIL,
      message: error.message,
    });
  }
}

function* fetchAvailablePeriod(action?: RiskAction) {
  try {
    const response = yield call(getAvailablePeriod, action?.payload)
    yield put({type: types.GET_RISK_AVAILABLE_PERIOD_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_AVAILABLE_PERIOD_FAIL,
      message: error.message,
    });
  }
}

function* fetchRiskKRICallout(action?: RiskAction) {
  try {
    const response = yield call(getRiskKRICallout, action?.payload)
    yield put({type: types.GET_RISK_KRI_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_KRI_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

function* fetchRiskMitigationCallout(action?: RiskAction) {
  try {
    const response = yield call(getRiskMitigationCallout, action?.payload)
    yield put({type: types.GET_RISK_MITIGATION_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_MITIGATION_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

function* fetchGasBusinessFLA(action?: RiskAction) {
  try {
    const response = yield call(getGasBusinessFLA, action?.payload)
    yield put({type: types.GET_GAS_BUSINESS_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GAS_BUSINESS_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLNGAFLA(action?: RiskAction) {
  try {
    const response = yield call(getLNGAFLA, action?.payload)
    yield put({type: types.GET_LNGA_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LNGA_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGPFLA(action?: RiskAction) {
  try {
    const response = yield call(getGPFLA, action?.payload)
    yield put({type: types.GET_GP_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GP_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLMTFLA(action?: RiskAction) {
  try {
    const response = yield call(getLMTFLA, action?.payload)
    yield put({type: types.GET_LMT_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LMT_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGCOFLA(action?: RiskAction) {
  try {
    const response = yield call(getGCOFLA, action?.payload)
    yield put({type: types.GET_GCO_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GCO_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGasBusinessSLA(action?: RiskAction) {
  try {
    const response = yield call(getGasBusinessSLA, action?.payload)
    yield put({type: types.GET_GAS_BUSINESS_SLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GAS_BUSINESS_SLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLNGASLA(action?: RiskAction) {
  try {
    const response = yield call(getLNGASLA, action?.payload)
    yield put({type: types.GET_LNGA_SLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LNGA_SLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGasBusinessTLA(action?: RiskAction) {
  try {
    const response = yield call(getGasBusinessTLA, action?.payload)
    yield put({type: types.GET_GAS_BUSINESS_TLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GAS_BUSINESS_TLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLNGATLA(action?: RiskAction) {
  try {
    const response = yield call(getLNGATLA, action?.payload)
    yield put({type: types.GET_LNGA_TLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LNGA_TLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGPTLA(action?: RiskAction) {
  try {
    const response = yield call(getGPTLA, action?.payload)
    yield put({type: types.GET_GP_TLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GP_TLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLMTTLA(action?: RiskAction) {
  try {
    const response = yield call(getLMTTLA, action?.payload)
    yield put({type: types.GET_LMT_TLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LMT_TLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGCOTLA(action?: RiskAction) {
  try {
    const response = yield call(getGCOTLA, action?.payload)
    yield put({type: types.GET_GCO_TLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GCO_TLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGPSLA(action?: RiskAction) {
  try {
    const response = yield call(getGPSLA, action?.payload)
    yield put({type: types.GET_GP_SLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GP_SLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLMTSLA(action?: RiskAction) {
  try {
    const response = yield call(getLMTSLA, action?.payload)
    yield put({type: types.GET_LMT_SLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LMT_SLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGCOSLA(action?: RiskAction) {
  try {
    const response = yield call(getGCOSLA, action?.payload)
    yield put({type: types.GET_GCO_SLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GCO_SLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLNGAAssetCallout(action?: RiskAction) {
  try {
    const response = yield call(getLNGAAssetCallout, action?.payload, action?.line, action?.calloutType)
    yield put({type: types.GET_LNGA_ASSET_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LNGA_ASSET_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

function* fetchLMTAssetCallout(action?: RiskAction) {
  try {
    const response = yield call(getLMTAssetCallout, action?.payload, action?.line, action?.calloutType)
    yield put({type: types.GET_LMT_ASSET_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LMT_ASSET_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

function* fetchGCOAssetCallout(action?: RiskAction) {
  try {
    const response = yield call(getGCOAssetCallout, action?.payload, action?.line, action?.calloutType)
    yield put({type: types.GET_GCO_ASSET_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GCO_ASSET_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

function* fetchGPAssetCallout(action?: RiskAction) {
  try {
    const response = yield call(getGPAssetCallout, action?.payload, action?.line, action?.calloutType)
    yield put({type: types.GET_GP_ASSET_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GP_ASSET_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

export function* workerFetchRiskData() {
  yield takeLatest(types.GET_RISK_TOP_REQUESTED, fetchRiskTop);
  yield takeLatest(types.GET_RISK_AVAILABLE_PERIOD_REQUESTED, fetchAvailablePeriod);
  yield takeLatest(types.GET_RISK_KRI_CALLOUT_REQUESTED, fetchRiskKRICallout);
  yield takeLatest(types.GET_RISK_MITIGATION_CALLOUT_REQUESTED, fetchRiskMitigationCallout);
  yield takeLatest(types.GET_GAS_BUSINESS_FLA_REQUESTED, fetchGasBusinessFLA);
  yield takeLatest(types.GET_LNGA_FLA_REQUESTED, fetchLNGAFLA);
  yield takeLatest(types.GET_GP_FLA_REQUESTED, fetchGPFLA);
  yield takeLatest(types.GET_LMT_FLA_REQUESTED, fetchLMTFLA);
  yield takeLatest(types.GET_GCO_FLA_REQUESTED, fetchGCOFLA);
  yield takeLatest(types.GET_GAS_BUSINESS_SLA_REQUESTED, fetchGasBusinessSLA);
  yield takeLatest(types.GET_LNGA_SLA_REQUESTED, fetchLNGASLA);
  yield takeLatest(types.GET_GP_SLA_REQUESTED, fetchGPSLA);
  yield takeLatest(types.GET_LMT_SLA_REQUESTED, fetchLMTSLA);
  yield takeLatest(types.GET_GCO_SLA_REQUESTED, fetchGCOSLA);
  yield takeLatest(types.GET_GAS_BUSINESS_TLA_REQUESTED, fetchGasBusinessTLA);
  yield takeLatest(types.GET_LNGA_TLA_REQUESTED, fetchLNGATLA);
  yield takeLatest(types.GET_GP_TLA_REQUESTED, fetchGPTLA);
  yield takeLatest(types.GET_LMT_TLA_REQUESTED, fetchLMTTLA);
  yield takeLatest(types.GET_GCO_TLA_REQUESTED, fetchGCOTLA);
  yield takeLatest(types.GET_LNGA_ASSET_CALLOUT_REQUESTED, fetchLNGAAssetCallout);
  yield takeLatest(types.GET_LMT_ASSET_CALLOUT_REQUESTED, fetchLMTAssetCallout);
  yield takeLatest(types.GET_GCO_ASSET_CALLOUT_REQUESTED, fetchGCOAssetCallout);
  yield takeLatest(types.GET_GP_ASSET_CALLOUT_REQUESTED, fetchGPAssetCallout);
}